import './Machines.css';

function Machines() {
    return (
        <div className='machines-container'>

        </div>
    )
};

export default Machines;
