import './MachineDashboard.css';

function MachineDashboard() {
    return (
        <div className='machine-dashboard'>
        </div>
    )
};

export default MachineDashboard;
